export const Environment = {
			production: true,
			appVersion: require('../../package.json').version + '-prod',
			analysisAPIRoot: '//devilb.blirevenup.com:443',
			iEAPIRoot: '//devilb.blirevenup.com:443',
			OIAPIRoot: '//devilb.blirevenup.com:443',
			dataCollectionAPIRoot: 'devilb.blirevenup.com:444/api/dc-v1',
			revOperationAPIRoot: 'devilb.blirevenup.com:443/api/revops',
			utmAPIRootWithoutHyphen: 'devilb.blirevenup.com:442/api/utm-v1/',
			utmAPIRoot: 'devilb.blirevenup.com:442/api/utm-v1',
			taskMiningAPIRoot: '-be1.opxce.com/api/tmv1',
			IIAPIRoot: '//devilb.blirevenup.com:443',
			revOpsRoot: '//devilb.blirevenup.com:443/api/revops/',
			revenupRoot: '//devilb.blirevenup.com:446/api/revenup/',
			genAI: '//devilb.blirevenup.com:446/api/revenup/intel/'
		  };